html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  background-color: #00807f;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
}

.icon {
  text-align: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 32px;
}

.icon:before {
  content: "";
  background: url("unsw-logo.27dc7564.png") 0 0 / 42px;
  width: 42px;
  height: 42px;
  display: block;
}

.icon span {
  max-width: 64px;
  display: block;
}

h3 {
  font-size: 12px;
}

.app {
  width: 50%;
  position: absolute;
  top: 120px;
}

body:has(.app + .app) .app {
  width: 42%;
  top: 420px;
  left: 42px;
}

body:has(.app + .app) .app + .app {
  top: 120px;
  left: auto;
  right: 42px;
}

* {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.my-sub-row {
  margin-bottom: 4px;
}

.my-sub-row:last-of-type {
  margin-bottom: 0;
}

button[aria-label="Help"] {
  margin-right: 10px;
}

.no-results {
  text-align: center;
  background-color: #ffc;
  padding: 10px;
  font-family: Comic Sans MS, Chalkboard SE, Comic Neue, sans-serif;
  font-size: 16px;
}

.no-results h3 {
  margin: 0 0 10px;
  font-size: 18px;
}

.no-results p {
  margin: 0;
}

:root {
  --dialog-blue: navy;
  --border-sunken-inner: inset -2px -2px var(--button-face), inset 2px 2px var(--button-shadow);
}

.progress-indicator {
  box-shadow: var(--border-sunken-inner);
  box-sizing: border-box;
  appearance: none;
  border: none;
  border-radius: 0;
  height: 32px;
  padding: 4px;
  position: relative;
}

.progress-indicator > .progress-indicator-bar {
  background-color: var(--dialog-blue);
  height: 100%;
  display: block;
}

.progress-indicator.segmented > .progress-indicator-bar {
  background-color: #0000;
  background-image: linear-gradient(90deg, var(--dialog-blue) 0 16px, transparent 0 2px);
  background-repeat: repeat;
  background-size: 18px 100%;
  width: 100%;
}

.show-debug .window.debug {
  display: initial;
}

.window.debug {
  width: 380px;
  height: calc(100vh - 20px);
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
}

.window.debug .window-body {
  height: calc(100% - 35px);
  margin: 0;
}

.window.debug pre {
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  height: 100%;
  max-height: calc(100% - 26px);
  overflow-y: auto;
}

.progress-indicator-bar {
  color: #fff;
  align-items: center;
  margin-right: -10px;
  padding-left: 10px;
  display: flex !important;
}

.filters {
  border-style: dashed;
}

.grouped-filters {
  justify-content: flex-start;
  display: flex;
}

.grouped-filters .filter {
  margin-left: 16px;
}

.grouped-filters .filter:first-of-type {
  margin-left: 0;
}

h3 {
  margin-bottom: 8px;
}

label {
  margin-bottom: 4px;
  display: block;
}

.filter-actions {
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.results-table {
  max-height: 300px;
  overflow-y: auto;
}

.results-table table {
  width: 100%;
}

.results-table th, .results-table td {
  text-align: left;
  vertical-align: top;
  padding: 2px 8px;
}

.results-table th.sortable {
  cursor: pointer;
}

.results-table th.sortable:after {
  content: "";
  background-size: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 8px;
}

.results-table th.sortable.sorted-asc:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 6H4V7.25H5.14286V8.5H6.28571V9.75H7.42857V11H8.57143V9.75H9.71429V8.5H10.8571V7.25H12V6Z' fill='black'/%3E%3C/svg%3E");
}

.results-table th.sortable.sorted-desc:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 11L12 11V9.75H10.8571L10.8571 8.5L9.71429 8.5V7.25L8.57143 7.25L8.57143 6L7.42857 6L7.42857 7.25L6.28571 7.25L6.28571 8.5H5.14286L5.14286 9.75L4 9.75L4 11Z' fill='black'/%3E%3C/svg%3E");
}

.results-tabs {
  margin-top: 10px;
}

.results-tabs menu .disabled a {
  color: gray;
  text-shadow: 1px 1px #fff;
}

.tab {
  display: none;
}

.tab--visible {
  display: block;
}

/*# sourceMappingURL=index.ec786645.css.map */
