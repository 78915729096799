.results-tabs {
  margin-top: 10px;

  menu .disabled a {
    color: grey;
    text-shadow: 1px 1px 0 #fff;
  }
}

.tab {
  display: none;

  &--visible {
    display: block;
  }
}
