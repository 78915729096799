html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background-color: #00807f;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 32px;
  left: 32px;
  text-align: center;
  color: white;

  &::before {
    content: '';
    display: block;
    width: 42px;
    height: 42px;
    background: url(./unsw-logo.png);
    background-size: 42px;
  }

  span {
    max-width: 64px;
    display: block;
  }
}

h3 {
  font-size: 12px;
}

.app {
  width: 50%;
  position: absolute;
  top: 120px;
}

body:has(.app + .app) .app {
  width: 42%;
  top: 420px;
  left: 42px;

  + .app {
    top: 120px;
    left: auto;
    right: 42px;
  }
}

* {
  cursor: default;
  user-select: none;
}

.my-sub-row {
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

button[aria-label='Help'] {
  margin-right: 10px;
}

.no-results {
  padding: 10px;
  background-color: #ffc;
  font-size: 16px;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  text-align: center;
  h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  p {
    margin: 0;
  }
}

// https://github.com/jdan/98.css/blob/ffa29f4cd976543a2151c3868cecf07e1e074989/style.css#L938
:root {
  --dialog-blue: #000080;
  --border-sunken-inner: inset -2px -2px var(--button-face),
    inset 2px 2px var(--button-shadow);
}

.progress-indicator {
  height: 32px;
  position: relative;
  box-shadow: var(--border-sunken-inner);
  padding: 4px 4px;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.progress-indicator > .progress-indicator-bar {
  height: 100%;
  display: block;
  background-color: var(--dialog-blue);
}

.progress-indicator.segmented > .progress-indicator-bar {
  width: 100%;
  background-color: transparent; /* resets the background color which is set to blue in the non-segmented selector */
  background-image: linear-gradient(
    90deg,
    var(--dialog-blue) 0 16px,
    transparent 0 2px
  );
  background-repeat: repeat;
  background-size: 18px 100%;
}

.show-debug .window.debug {
  display: initial;
}

.window.debug {
  position: fixed;
  display: none;
  top: 10px;
  right: 10px;
  width: 380px;
  height: calc(100vh - 20px);

  .window-body {
    margin: 0;
    height: calc(100% - 35px);
  }

  pre {
    height: 100%;
    max-height: calc(100% - 26px);
    overflow-y: auto;
    user-select: text;
    cursor: text;
  }
}
