.filters {
  border-style: dashed;
}

.grouped-filters {
  display: flex;
  justify-content: flex-start;

  .filter {
    margin-left: 16px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

h3 {
  margin-bottom: 8px;
}

label {
  display: block;
  margin-bottom: 4px;
}

.filter-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
